<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/nodes"></ion-back-button>
        </ion-buttons>
        <ion-title>
          <div class="header_el">
            <router-link to="/nodes">
              <div class="logo_img">
                <img
                  class="logo_img"
                  src="/assets/Logo-Bianco.png"
                  alt="logo-bianco"
                />
              </div>
            </router-link>
            <div class="title">
              <h1>|| Trinci-Monitor ||</h1>
            </div>
          </div>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="demo-control-panel">
        <label>Node count:</label>
        <el-input-number v-model="nodeCount" :min="3" :max="200" />
        <label>(&lt;= 200)</label>
      </div>

      <v-network-graph
        :zoom-level="0.5"
        :nodes="nodes"
        :edges="edges"
        :configs="configs"
        :layers="layers"
      >
       
      </v-network-graph>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";
//import axios from "axios";
import { reactive, ref, watchEffect } from "vue";
import * as vNG from "v-network-graph";
import {
  ForceLayout,
  //ForceNodeDatum,
  //ForceEdgeDatum,
} from "v-network-graph/lib/force-layout";
import SocketioService from '../services/socketio.service.js';
export default {
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonButtons,
    IonBackButton,
  },
  data() {
    const nodeCount = ref(30);
    const nodes = reactive({});
    const edges = reactive({});
    const socketData = reactive({});
    const isConnected = reactive(false);
    watchEffect(() => {
      buildNetwork(nodeCount.value, nodes, edges);
    });
    const ACTIVE = "#00ee00";
    // additional layers definition
    const layers = {
      // {layername}: {position}
      badge: "nodes",
    };
    //const INACTIVE = "#ff0000"
    const configs = reactive(
      vNG.defineConfigs({
        view: {
          layoutHandler: new ForceLayout({
            positionFixedByDrag: true,
            positionFixedByClickWithAltKey: true,
            // * The following are the default parameters for the simulation.
            // * You can customize it by uncommenting below.
            // createSimulation: (d3, nodes, edges) => {
            //   const forceLink = d3.forceLink<ForceNodeDatum, ForceEdgeDatum>(edges).id(d => d.id)
            //   return d3
            //     .forceSimulation(nodes)
            //     .force("edge", forceLink.distance(100))
            //     .force("charge", d3.forceManyBody())
            //     .force("collide", d3.forceCollide(50).strength(0.2))
            //     .force("center", d3.forceCenter().strength(0.05))
            //     .alphaMin(0.001)
            // }
          }),
        },
        node: {
          normal: {
            color: (n) => (n.color != "" ? n.color : "#4466cc"),
          },
          selectable: true,
          label: {
            visible: true,
            fontFamily: undefined,
            fontSize: 20,
            lineHeight: 1.1,
            color: "#000000",
            margin: 4,
            direction: "south",
            text: "name",
          },
          focusring: {
            visible: true,
            width: 4,
            padding: 3,
            color: "#eebb00",
            dasharray: "0",
          },
          edge: {
            normal: {
              width: 2,
              color: "#888888",
              dasharray: 4, //edge =>nodes[edge.source].active && nodes[edge.target].active ? 4 : 0,
              animate: true, // edge => nodes[edge.source].active && nodes[edge.target].active,
            },
            hover: {
              color: "#222222",
            },
            margin: 2,
            marker: {
              source: {
                type: "circle",
                width: 5,
                height: 5,
                margin: 1,
                color: ACTIVE, //([edge, _stroke]) => (nodes[edge.source].active ? ACTIVE : INACTIVE),
              },
              target: {
                type: "circle",
                width: 5,
                height: 5,
                margin: 1,
                color: ACTIVE, //([edge, _stroke]) => (nodes[edge.target].active ? ACTIVE : INACTIVE),
              },
            },
          },
        },
      })
    );

    function buildNetwork(count, nodes, edges) {
      const idNums = [...Array(count)].map((_, i) => i); // [0,1,2,3,...]

      // nodes
      const newNodes = Object.fromEntries(
        idNums.map((id) => [
          `node${id}`,
          { name: `Node ${id}`, type: "red", color: "" },
        ])
      ); // {node0 : {} , node1: {} , ....}
      Object.keys(nodes).forEach((id) => delete nodes[id]);
      Object.assign(nodes, newNodes);

      // edges
      const makeEdgeEntry = (id1, id2) => {
        return [
          `edge${id1}-${id2}`,
          { source: `node${id1}`, target: `node${id2}` },
        ];
      };
      const newEdges = Object.fromEntries([
        ...idNums
          .map((n) => [n, (Math.floor(n / 5) * 5) % count])
          .map(([n, m]) => (n === m ? [n, (n + 5) % count] : [n, m]))
          .map(([n, m]) => makeEdgeEntry(n, m)),
      ]);
      Object.keys(edges).forEach((id) => delete edges[id]);
      Object.assign(edges, newEdges);
      
    }
    return {
      nodes,
      edges,
      configs,
      layers,
      network: this.$route.params.network,
      pollingStats: null,
      socketData,
      isConnected
    };
  },
  methods: {
    pollDataStats() {
      this.pollingStats = setInterval(() => {
        const randomNodes = this.shuffleArray(Object.keys(this.nodes));
        //console.log(randomNodes,Object.keys(this.nodes));
        const color =  Math.floor(Math.random() * 16777215).toString(16);
        let i=100;
        randomNodes.forEach((id) => {
          
            setTimeout(() => {
                this.colorateNode(id,"#"+color);
            },i+=75);
          
        });
      }, 5000);
    },
    shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    },
    colorateNode(nodeId,color,time=2000) {
        if(this.nodes[nodeId]) {
            this.nodes[nodeId].color = color;
            setTimeout(() => {
                this.nodes[nodeId].color = "";
            },time)
        }
    }
  },
  

  computed: {
    node: function () {
      return this.nodeData;
    },
  },

  ionViewWillLeave() {
    clearInterval(this.pollingStats);
    SocketioService.disconnect();
    // console.log(this.pollingStats, 'unmounted');
  },

  ionViewWillEnter() {
    this.pollDataStats();
    const socket = SocketioService.setupSocketConnection();
    socket.on('node:tx', (data) => {
        console.log(data);
    });
  },
};
</script>

<style>
.stats {
  padding: 30px;
}

.stats_box {
  margin: 10px 0;
  padding: 10px 10px;
  border-radius: 10px;
}

.details_title {
  text-align: center;
  margin-bottom: 15px;
}

.header_el {
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}
.logo_img {
  height: 60px;
  margin-right: 10px;
}

h1 {
  text-transform: uppercase;
}

h5 {
  margin: 10px 0;
}

h4 {
  margin: 10px 0;
}

table {
  width: 100%;
  text-align: center;
}

table,
th,
td {
  border: 1px solid #989aa2;
  border-collapse: collapse;
}

.msgs_list > li {
  margin: 5px 15px;
}

.msgs_list > li > p {
  font-size: 12px;
}

.success {
  background-color: yellowgreen;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.danger {
  background-color: red;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .header_el > .title {
    font-size: 20px;
  }

  .logo_img {
    display: none;
  }

  h1 {
    text-transform: uppercase;
    font-size: 20px;
  }

  h5 {
    margin: 10px 0;
    font-size: 14px;
  }

  h4 {
    margin: 10px 0;
    font-size: 15px;
  }

  h3 {
    font-size: 16px;
  }

  .stats_box {
    margin: 5px 0;
    padding: 0px 10px;
  }
}
</style>
