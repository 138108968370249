import { io } from 'socket.io-client';

class SocketioService {
    socket;
    constructor() { }

    setupSocketConnection() {
        this.socket = io("https://monitor.affidaty.net/");
        return this.socket;
    };
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    };
}

export default new SocketioService();